import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { useGetCheckinEntryDetailLazyQuery } from '~/graphql';
import { getNode } from '~/graphql/utility';
import { checkinEntryProfileDrawerState } from '~/state/reception/atoms';

export const useFetchCheckinEntry = () => {
  const { isOpen, checkinEntryId } = useRecoilValue(checkinEntryProfileDrawerState);

  const [getCheckin, { data, loading }] = useGetCheckinEntryDetailLazyQuery({
    fetchPolicy: 'network-only',
  });

  const checkinEntry = getNode(data, 'CheckinEntry');

  useEffect(() => {
    if (isOpen && checkinEntryId) {
      getCheckin({
        variables: {
          id: checkinEntryId,
        },
      });
    }
  }, [getCheckin, isOpen, checkinEntryId]);

  return { checkinEntry, loading };
};
