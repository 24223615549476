import React, { useCallback } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { DateLabel, Flex, Name, Table, Text } from '~/components/blocks';
import { AppointmentStatusLabel } from '~/components/partials';
import { DeliveryMethodField } from '~/components/partials/DeliveryMethodField';
import { MedicationInstructionMethodLabel } from '~/components/partials/MedicationInstructionMethodLabel';
import { ReceptionMemos } from '~/components/partials/ReceptionMemos';
import { AppointmentDeliveryMethod, ReceptionListAppointmentFragment } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { profileDrawerState } from '~/state/partials/patient_profile_drawer/atoms';
import {
  checkinEntryProfileDrawerState,
  draftAppointmentPatientProfileDrawerState,
  outpatientQuestionnaireEntryProfileDrawerState,
  receptionPageInfoState,
  webVisitorProfileDrawerState,
} from '~/state/reception/atoms';
import { Label } from '~/utils/label';

import { TableRow } from './TableRow';

type Props = {
  appointment: ReceptionListAppointmentFragment;
  updated: boolean;
  onAnimationEnd: (appointmentId: string) => void;
  activeMedixs: boolean;
};

const formatAppointmentData = (appointment: ReceptionListAppointmentFragment) => {
  const patient = appointment.patient;
  return {
    id: appointment.id,
    receptAt: appointment.createdAt,
    status: appointment.status,
    patientId: patient?.id,
    familyName: patient?.familyName,
    givenName: patient?.givenName,
    phoneticFamilyName: patient?.phoneticFamilyName,
    phoneticGivenName: patient?.phoneticGivenName,
    birthDate: patient?.birthDate,
    sex: patient?.sex,
    start: appointment.start,
    end: appointment.end,
    memo: appointment.description,
    telemedicine: appointment.telemedicine,
    immediate: appointment.immediate,
    medixsReceptionId: appointment.medixsReception?.receptionId,
    deliveryMethod: appointment.deliveryMethod,
    uberDelivery: appointment.uberDelivery,
  };
};

export const Appointment = React.memo((props: Props) => {
  const { onAnimationEnd } = props;
  const { date } = useRecoilValue(receptionPageInfoState);
  const { enable: enabledUber } = useUberOrganizationSetting();

  const appointment = formatAppointmentData(props.appointment);
  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      () => {
        set(profileDrawerState, {
          isOpen: true,
          patientId: appointment.patientId as string,
          appointmentId: appointment.id,
          error: null,
        });
        reset(draftAppointmentPatientProfileDrawerState);
        reset(webVisitorProfileDrawerState);
        reset(checkinEntryProfileDrawerState);
        reset(outpatientQuestionnaireEntryProfileDrawerState);
      },
    [appointment.id, appointment.patientId],
  );

  const handleAnimationEnd = useCallback(
    () => onAnimationEnd(props.appointment.id),
    [onAnimationEnd, props.appointment.id],
  );

  return (
    <TableRow updated={props.updated} onClick={handleClick} onAnimationEnd={handleAnimationEnd}>
      <Table.Td nowrap>
        <Flex alignItems="center">
          {appointment.start && appointment.end && (
            <DateLabel>
              {!date && <DateLabel.Time>{Label.YYYYMMDDja(appointment.start)}</DateLabel.Time>}
              <DateLabel.Day>
                {Label.HHMM(appointment.start)}-{Label.HHMM(appointment.end)}
              </DateLabel.Day>
            </DateLabel>
          )}
        </Flex>
      </Table.Td>
      <Table.Td>
        <AppointmentStatusLabel
          status={props.appointment.status}
          uberDelivery={appointment.uberDelivery}
          isSameDayDelivery={
            appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
          }
        />
      </Table.Td>
      {props.activeMedixs && (
        <Table.Td nowrap>
          {appointment.medixsReceptionId || (
            <Text color="navy" size="s">
              未連携
            </Text>
          )}
        </Table.Td>
      )}
      <Table.Td nowrap>
        <Name
          familyName={appointment.familyName}
          givenName={appointment.givenName}
          phoneticFamilyName={appointment.phoneticFamilyName}
          phoneticGivenName={appointment.phoneticGivenName}
        />
      </Table.Td>
      <Table.Td nowrap>
        <Text size="m" whiteSpace="nowrap">
          {appointment.birthDate ? Label.age(appointment.birthDate) : ''}
        </Text>
        <Text size="s" whiteSpace="nowrap">
          （{appointment.sex ? Label.sex(appointment.sex) : ''}）
        </Text>
      </Table.Td>
      <Table.Td>
        <Flex alignItems="center">
          <MedicationInstructionMethodLabel
            method={appointment.telemedicine ? 'telemedicine' : 'faceToFace'}
            use="short"
            size="s"
          />
        </Flex>
      </Table.Td>
      <Table.Td>
        <DeliveryMethodField deliveryMethod={appointment.deliveryMethod} size="m" />
      </Table.Td>
      {enabledUber && (
        <Table.Td>
          <Text size="m" whiteSpace="nowrap">
            {appointment.deliveryMethod !== AppointmentDeliveryMethod.SameDayDelivery
              ? '-'
              : appointment.uberDelivery?.orderNumber || '未確定'}
          </Text>
        </Table.Td>
      )}
      <Table.Td>
        <Text size="m" whiteSpace="nowrap">
          {Label.YYYYMMDDja(appointment.receptAt)}&nbsp;{Label.HHMM(appointment.receptAt)}
        </Text>
      </Table.Td>
      <Table.Td>
        <ReceptionMemos memo={appointment.memo} />
      </Table.Td>
    </TableRow>
  );
});

Appointment.displayName = 'Appointment';
