import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';
import React, { useCallback, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import { Alert, Box, Button, Flex, Icon, Loader, Text } from '~/components/blocks';
import { PdfViewer } from '~/components/pages/Reception/DraftAppointmentPatientProfileDrawer/ProfileDrawer/FileArea/InsuranceCard/PdfViewer';
import { useFetchInsuranceCards } from '~/components/pages/Reception/DraftAppointmentPatientProfileDrawer/ProfileDrawer/use-fetch-insurance_cards';
import { TabMap } from '~/constants/tab';
import { filePageInfoState, tabState } from '~/state/partials/patient_karte/atoms';
import { draftAppointmentPatientFileAreaState } from '~/state/reception_drawer/atoms';
import { isPdf } from '~/utils/file';
import { openWithNoOpener } from '~/utils/window';

import { ImgViewer } from './ImgViewer';
import { useFetchDraftAppointment } from './use-fetch-draft_appointment';

const DEFAULT_HEIGHT = '130px';

const PreviewButton = styled(Button)(({ theme }) =>
  css({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    width: '40px',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radii.circle,
    opacity: 0,
    transform: 'translate(-50%,-50%)',
    transition: `opacity linier ${theme.transitions.fast}`,
  }),
);

const Content = styled(Box)(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    cursor: 'pointer',
    minHeight: DEFAULT_HEIGHT,
    position: 'relative',
    [`&:hover > ${PreviewButton}`]: {
      opacity: 1,
    },
  }),
);

export const InsuranceCard = () => {
  const theme = useTheme();
  const { draftAppointmentId, patientId } = useRecoilValue(draftAppointmentPatientFileAreaState);
  const { isActivePatient, loading: draftAppointmentLoading } = useFetchDraftAppointment({
    draftAppointmentId,
  });
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [hasPdfError, setHasPdfError] = useState(false);

  const {
    loading: insuranceCardsLoading,
    timeout,
    hasCertificate,
    error,
    insuranceCards,
  } = useFetchInsuranceCards(patientId);
  const hasPdf = insuranceCards.some((card) => isPdf(card.attachment.contentType));

  const handleClickCertificate = useRecoilCallback(
    ({ set }) =>
      () => {
        if (patientId) {
          set(tabState, { type: TabMap.file });
          set(filePageInfoState, (_state) => ({
            ..._state,
            patientId,
            page: 1,
            filter: { sent: false, received: true },
          }));
        }
      },
    [patientId],
  );

  const handleClick = useCallback(() => {
    if (patientId) {
      openWithNoOpener(`/insurance_card_preview/${patientId}`, {
        target: patientId,
        width: 640,
        height: 720,
        bgColor: '#525659',
      });
    }
  }, [patientId]);

  const handlePdfError = useCallback((error: Error) => {
    setHasPdfError(true);
    Sentry.captureMessage(`react-pdf Error: message=${error.message}`);
  }, []);
  const handlePdfLoad = useCallback(() => setIsPdfLoading(false), []);

  return (
    <Flex flexDirection="column">
      <Flex marginBottom={theme.space.l}>
        <Icon size="xl" icon="insurance" />
        <Text fontWeight="bold" marginLeft={theme.space.xs}>
          保険証
        </Text>
      </Flex>
      <Box>
        {timeout ? (
          <Alert marginTop={theme.space.m}>
            保険証の取得に時間がかかっています。
            <br />
            しばらく待ってから、再度アクセスしてください
          </Alert>
        ) : error ? (
          <Box padding={theme.space.m}>
            <Alert status="error">取得できませんでした</Alert>
          </Box>
        ) : !isActivePatient ? (
          <Box padding={theme.space.m}>
            <Alert status="info">退会済みのため表示できません</Alert>
          </Box>
        ) : draftAppointmentLoading || insuranceCardsLoading ? (
          <Box
            backgroundColor={theme.colors.background.bg}
            height={DEFAULT_HEIGHT}
            position="relative"
          >
            <Loader open inside appearance="white" logoSize="half" />
          </Box>
        ) : insuranceCards.length > 0 ? (
          <Content onClick={hasPdf && (hasPdfError || isPdfLoading) ? undefined : handleClick}>
            {insuranceCards.map((card) => {
              if (isPdf(card.attachment.contentType)) {
                return (
                  <PdfViewer
                    key={card.attachment.id}
                    src={card.attachment.url}
                    onError={handlePdfError}
                    onLoad={handlePdfLoad}
                  />
                );
              } else {
                return (
                  <ImgViewer
                    key={card.attachment.id}
                    alt={card.attachment.title}
                    src={card.attachment.url}
                  />
                );
              }
            })}
            <PreviewButton icon use="base">
              <Icon color="white" icon="blank" size="l" />
            </PreviewButton>
          </Content>
        ) : (
          <Box padding={theme.space.m}>
            <Alert status="info">登録されていません</Alert>
          </Box>
        )}
        {hasCertificate && (
          <Box marginTop={theme.space.xl} backgroundColor={theme.colors.background.default}>
            <Button size="s" onClick={handleClickCertificate}>
              <Icon icon="document" size="m" color="black" />
              公費受給者証を確認
            </Button>
          </Box>
        )}
      </Box>
    </Flex>
  );
};
